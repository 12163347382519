<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-form-payment-advance-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.formPayment.vendor.actions.create') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-form-payment-advance-detail', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Account Type -->
      <template #cell(label)="{ item }">
        <!-- {{ data.value.type_name }} -->
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.contact.label }}
        </span>
        <!-- <small class="font-italic">{{ item.remark }} {{ $t('globalSingular.via') }} <span class="font-weight-bolder text-primary ">{{ item.account.account_name }}</span>  </small> -->
      </template>

      <!-- Column: Bank -->
      <template #cell(bank)="{ item }">
        <div v-for="(items, index) in item.contact.bank_information" :key="index">
          <p v-if="items.isContact">
            <b>
              {{ items.bankName }}
            </b>
            - {{ items.bankAccountNumber }}
          </p>
        </div>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]">
          {{
            formatCurrency(item.amount)
          }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <!-- Column: actions -->
      <template #cell(actions)="{item}">
        <div>
        <b-link
        @click="downloadPDF(item.id)"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
           <feather-icon
            icon="FileTextIcon"
            size="21"
          />
          </span>
        </b-link>
        </div>
      </template>


    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'

import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'
import jsPDF from 'jspdf' 
import useHttp from '@/comp-functions/useHttp'
import { ref } from '@vue/composition-api'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge
  },
  setup () {
    const { $t } = useLocalization()
    const { $get } = useHttp()


    // Table Handlers
    const tableColumns = [
      { key: 'code', label: '#' },
      { key: 'date', label: $t('globalSingular.date'), sortable: true  },
      { key: 'office.brand', label: $t('globalSingular.branch') },
      { key: 'label', label: $t('globalSingular.description') },
      { key: 'bank', label: $t('globalSingular.bank') },
      { key: 'amount', label: $t('globalSingular.amount'), thClass: 'text-right', tdClass: 'text-right' },
      { key: 'tags', label: $t('globalSingular.tags') },
      { key: 'audit', label: $t('globalSingular.audit') },
      { key: 'actions', label: $t('globalSingular.actions'), thClass: 'text-center', tdClass: 'text-center' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'remark'
    ]

    const dataPrint = ref()

     const getDataPreview = async (id) => {
      const { data } =  await $get({
        url: `transaction/purchase/payment-voucher/reportAdvance/${id}`
      })
      dataPrint.value = data
    }

    // download pdf 
    const downloadPDF = async(id) =>{
      await getDataPreview(id)
       const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: 'a5'
      });


      // advance report 
      doc.setFontSize(15).setFont('','bold')
      doc.text("ADVANCE REPORT", 83, 10)

      // line dibawah advance report
      doc.line(83, 11, 132, 11)

      let xPos = 5
      let yPos = 16.5

      let xReactPos = 3
      let yReactPos = 12


      doc.setFontSize(9).setFont('','bold')
      
      // logo
      let imageData = `${window.location.origin}/images/logo-pdf.png`
      doc.addImage(imageData, 'PNG', 3, 0, 35, 11);

      doc.rect(xReactPos, yReactPos, 10, 7)
      doc.text("Date", xPos, yPos)

      xReactPos += 10
      yReactPos += 0

      doc.rect(xReactPos, yReactPos, 30, 7)
      xPos += 10
      doc.text(`${dataPrint.value.dateForm}`, xPos, yPos)

      doc.setFontSize(10).setFont('','bold')

      // kotak slip no
      doc.rect(xReactPos + 150 , yReactPos, 14, 7)
      doc.text("Slip No.", xPos+ 149, yPos)
      doc.rect(xReactPos + 164 , yReactPos, 29, 7)

      doc.text(`${dataPrint.value.masterRight.slip_no}`, xPos + 169, yPos + 0.4)


      // kotak pertama
      doc.rect(xReactPos - 10, yReactPos + 10, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 10, 101.5, 7)
      doc.text("Paid To  :  " + `${dataPrint.value.master.paid_to}`, xPos - 10, yPos + 10)
      doc.text("No Advance         :  " + `${dataPrint.value.masterRight.no_advance}`, xPos + 91, yPos + 10)

      // kotak kedua 
      doc.rect(xReactPos - 10, yReactPos + 17, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 17, 101.5, 7)
      doc.text("No. PR   :  " + `${dataPrint.value.master.no_pr}`, xPos - 10, yPos + 17)
      doc.text("Advance               :  " + `${formatCurrency(dataPrint.value.masterRight.advance)}`, xPos + 91, yPos + 17)

      // kotak ketiga
      doc.rect(xReactPos - 10, yReactPos + 24, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 24, 101.5, 7)
      doc.text("Dept.      :  " + `${dataPrint.value.master.work_unit}`, xPos - 10, yPos + 24)
      doc.text("Total Paid            :  " + `${formatCurrency(dataPrint.value.masterRight.total_paid)}`, xPos + 91, yPos + 24)

      // kotak keempat
      doc.rect(xReactPos - 10, yReactPos + 31, 101.5, 7)
      doc.rect(xReactPos + 91.5, yReactPos + 31, 101.5, 7)
      doc.text("For.        :  " + `${dataPrint.value.master.for}`, xPos - 10, yPos + 31)
      doc.text("Saldo Advance     :  " + `${formatCurrency(dataPrint.value.masterRight.saldo_advance)}`, xPos + 91, yPos + 31)
      
      /* kotak kelima */ 
      doc.rect(xReactPos - 10, yReactPos + 38, 203, 7)

      /* Detail of Invoice */ 
      doc.text("Detail Of Invoice", xPos - 12, yPos + 44)
      
      /* garis bawah detail of invoice */  
      doc.line(3, 62, 206, 62)
      doc.line(3, 67, 206, 67)

      // garis lurus pojok kiri
      doc.line(3, 62, 3, 125)
      /* no */ 
      doc.text("No", xPos - 10.5, yPos + 49)
      doc.line(10, 62, 10, 120)
      /* Description */ 
      doc.text("Description", xPos + 17, yPos + 49)
      doc.line(70, 62, 70, 120) 
      /* Date Invoice */
      doc.text("Date", xPos + 60, yPos + 49)
      doc.line(100, 62, 100, 120)
      /* Invoice Number */
      doc.text("PO Number", xPos + 97, yPos + 49)
      doc.line(150, 62, 150, 125) 
      /* Amount */
      doc.text("Amount", xPos + 155, yPos + 49)
      doc.line(206, 62, 206, 125) 
      /* Garis paling bawah */
      doc.line(3, 120, 206, 120)

     let looping = dataPrint.value.detailsPo
     let baris = 3
     let totalAmount = 0
     for (let index = 0; index < looping.length; index++) {
        baris = baris + 3
        let total = 43 + baris;
        totalAmount = totalAmount + dataPrint.value.detailsPo[index].amount
        doc.text(`${index + 1}.`, xPos - 10.5, (yPos + total + baris))
        doc.text(`${dataPrint.value.detailsPo[index].descptions}`, xPos - 3, (yPos + total + baris))
        doc.text(`${dataPrint.value.detailsPo[index].date}`, xPos + 61, (yPos + total + baris))
        doc.text(`${dataPrint.value.detailsPo[index].no_po}`, xPos + 87, (yPos + total + baris))
        doc.text("Rp.                         " + `${formatCurrency(dataPrint.value.detailsPo[index].amount, false)}`, xPos + 137, (yPos + total + baris))
     }

      doc.line(3, 125, 206, 125)
      doc.text("Total Paid :", xPos + 116, yPos + 107)
      doc.text("Rp.                         " + `${formatCurrency(totalAmount,false)}`, xPos + 137, yPos + 107)

      doc.rect(xReactPos+ 50, yReactPos + 115, 45, 5)
      doc.text("Report By", xPos + 62, yPos + 114)

      doc.rect(xReactPos+ 50, yReactPos + 120, 45, 5)
      doc.text("Date: " + `${dataPrint.value.master.date_now}`, xPos + 50, yPos + 119)

      doc.rect(xReactPos+ 50, yReactPos + 125, 45, 10)


      doc.rect(xReactPos+ 98, yReactPos + 115, 45, 5)
      doc.text("Checked By", xPos + 109, yPos + 114)

      doc.rect(xReactPos+ 98, yReactPos + 120, 45, 5)
      doc.text("Date: " + `${dataPrint.value.master.date_now}`, xPos + 97, yPos + 119)
      doc.rect(xReactPos+ 98, yReactPos + 125, 45, 10)

      await doc.save(`${dataPrint.value.masterRight.slip_no}.pdf`, {returnPromise: true})


      // doc.output('dataurlnewwindow');
      
    }

    return {
      downloadPDF,
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'transaction/purchase/payment-voucher?form_type=ADVANCE&is_closed=false'
      })
    }
  }
}
</script>
